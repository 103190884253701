html {
  background-color: #3e3e3e;
  color: #efefef;
}

.App {
  text-align: center;
  background-color: #3e3e3e;
  color: #efefef;
}

.buttons {
  margin: 5px;
}

.greenbox {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  border: 1px solid #efefef;
  padding: 0.5em;
  margin: 0.1em;
  border-radius: 5px;
  background: green;
}

.diminished {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-style: italic;
  color: #a0a0a0;
}

.solution {
  padding: 1em;
}
